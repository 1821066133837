@charset "UTF-8";

//各種パラメータ
@import 'param';

//プロジェクトパラメータ
@import 'object/project/project_variables';

//ミックスイン
@import 'mixin';

//CSSリセット
@import 'reset';

// コンポーネント
@import "plugin/_lightbox.min.scss";
@import "plugin/_swiper.min.scss";


/* ---------------------------------------------------------
    COMMON
--------------------------------------------------------- */
* {
  box-sizing: border-box;
}


.fadeInDown {
  -webkit-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.7s;
  -ms-animation-duration: 0.7s;
  animation-duration: 0.7s;
  visibility: visible !important;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.pc {
  display: block !important;
  @include mq(md) {
    display: none !important;
  }
  &#nav {
    @include mq(lg) {
      display: none !important;
    }
  }
}

.sp {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}
header.sp {
  @include mq(lg) {
    display: block !important;
  }
}

/* 固有
================================================== */
html, body {
  overflow-x: hidden;
}
html{
  font-size: 10px;
  height: -webkit-fill-available;
}
body{
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-size: 1.6rem;
  font-family: '游ゴシック', YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka,
    'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
  color: #000;
  position: relative;
}

h2 {
  margin: 0 0 30px;
  padding: 0;
  font-size: 32px;
  text-align: center;
  font-weight: normal;
  @include mq(md) {
    font-size: 31px;
  }
}

img {
  max-width: 100%;
  vertical-align: top;
}
p {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  position: relative;
  text-decoration: none;
  color: #000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.en {
  font-family: $font_family_03;
}

.inner {
  max-width: 1040px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

@media all and (max-width: $breakpoint_l) {
  .inner {
    margin: 0;
    padding: 0 10px;
  }
}

//新着記事
.new {
  position: absolute;
  background-color: $key_color_01;
  border: 1px solid $key_color_02;
  color: $text_color_02;
  font-style: italic;
  width: 40px;
  height: 40px;
  font-size: 10px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(md) {
    width: 37px;
    height: 37px;
    font-size: 8px;
  }

  .lead {
    // @include fz_vw(10);
    transform: rotate(-45deg) scale(0.9);
    -webkit-transform: rotate(-45deg) scale(0.9);
  }
}

.ttl {
  font-style: italic;
  margin: 0 0 60px;
}

.mod_title_01 {
  position: relative;
  font-style: italic;
  font-size: 32px;
  letter-spacing: 3px;
  margin: 0 0 40px;
  span {
    display: block;
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 12px;
    color: $key_color_01;
    font-weight: normal;
    letter-spacing: 1px;
    @include mq(md) {
      font-size: 11px;
    }
  }
}

@media all and (max-width: $breakpoint_m) {
  .mod_title_01 {
    margin-bottom: 30px;
  }
}

// 共通ボタン
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  padding: 5px 1.8em;
  height: 55px;
  background: $key_color_01;
  border-radius: 32px;
  // line-height: 55px;
  color: $text_color_02;
  border: 1px solid $key_color_01;
  letter-spacing: 1.5px;
  text-align: center;
  // font-style: oblique;
  p {
    margin: 0 auto;
  }
  @include mq(md) {
    border-radius: 50px;
    width: 95%;
  }
  &::before {
    content: '';
    display: block;
    position: absolute; /* .navToggleに対して */
    border-bottom: solid 1px $key_color_01;
    top: 22px;
    @include rv_vw(20);
    width: 7px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    @include mq(md) {
      right: 20px;
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute; /* .navToggleに対して */
    border-bottom: solid 1px $key_color_01;
    top: 27px;
    @include rv_vw(20);
    width: 7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    @include mq(md) {
      right: 20px;
    }
  }
}
.btn:hover {
  background: $key_color_02;
  border: 1px solid $key_color_01;
  color: $key_color_01;
  &::before {
    border-bottom: solid 1px $key_color_02;
    @include rv_vw(15);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    @include mq(md) {
      right: 20px;
    }
  }
  &::after {
    border-bottom: solid 1px $key_color_02;
    @include rv_vw(15);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    @include mq(md) {
      right: 20px;
    }
  }
}
//色反転ボタン
.btn_rev {
  &::before {
    border-bottom: solid 1px $key_color_02;
  }
  &::after {
    border-bottom: solid 1px $key_color_02;
  }
}
.btn_rev:hover {
  &::before {
    border-bottom: solid 1px $key_color_01;
  }
  &::after {
    border-bottom: solid 1px $key_color_01;
  }
}

// サイド追従「資料請求はこちら」
a.charge {
  background-color: $text_color_01;
  color: $text_color_02;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: fixed;
  width: 69px;
  height: 190px;
  top: 40%;
  right: 0;
  font-size: 14px;
  padding-top: 14px;
  line-height: 5;
  transition: none;
  .fa-file-alt {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .fa-chevron-circle-right {
    font-size: 14px;
    padding-top: 10px;
  }
}

footer {
  padding-top: 60px;
  background: $key_color_01;
  color: $text_color_02;
}

/* Page Button
	================================================== */
#page_btn {
  display: table;
  width: auto;
  margin: 0 auto;
  padding-top: 30px;
  @include mq(md) {
    padding-top: 10px;
  }
  li {
    font-family: $font_family_03;
    display: inline-block;
    margin: 0 2px;
    a {
      display: block;
      text-align: center;
      // font-weight: bold;
      color: $text_color_01;
      background-color: $key_color_02;
      font-family: $font_family_03;
      font-size: 15px;
      width: 33px;
      height: 33px;
      border-radius: 50px;
      padding-top: 8px;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;

      @include mq(sm) {
        font-size: 14px;
      }
      &:hover {
        background-color: $key_color_01;
        color: $text_color_02;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        color: $text_color_02;
      }
    }

    &.active {
      a {
        background-color: $key_color_01;
        color: $text_color_02;
      }
    }
    &.next_page,
    &.prev_page {
      font-family: $font_family_base;
      a {
        font-weight: normal;
        width: auto;
        padding: 8px 7px 0px;
        margin-left: 15px;

        @include mq(sm) {
          margin-left: 0;
        }
      }
    }
  }
}

#page_btn.single {
  li {
    a {
      margin: 0;
    }
  }
  li.next_page {
    padding: 8px 0px 0px 35px;
    margin-top: 28px;
    margin-bottom: 20px;
    @include mq(md) {
      padding: 20px 25px 0px;
    }
    @include mq(sm) {
      padding: 20px 10px 0px;
    }
  }
} /* Page Button - - - - - - - - - - - - - - - - - - */

/* よく使うクラス
================================================== */
.clearfix {
  overflow: hidden;
  zoom: 1;
}
.italic {
  letter-spacing: 3px;
  transform: scaleX(0.9) skewX(-10deg);
}
